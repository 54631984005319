import React, { FunctionComponent, useState } from 'react';
import {
  Button,
  Controller,
  FormHelperText,
  hookformResolver,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  useForm,
} from '@tf/design-system-react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import useLogin from './useLogin';
import { schema } from './validationSchema';

import * as styles from './Login.module.scss';

interface Props {
  forgotPasswordLinkText?: string | null;
}

const LoginForm: FunctionComponent<Props> = ({ forgotPasswordLinkText }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { errorMessage, onSubmit } = useLogin();

  const defaultValues = {
    username: '',
    password: '',
  };

  const { handleSubmit, control, errors } = useForm({
    resolver: hookformResolver(schema),
    defaultValues,
  });

  const handleClickShowPassword = (): void => {
    setShowPassword(!showPassword);
  };

    const redirectToIdentityApp = (): void => {
      window.location.assign('/tradefloor/oauth2/authorization/om');
    };

  return (
    <>
      <form className={styles.loginForm} noValidate>
{/*         <InputLabel>Email address</InputLabel> */}
{/*         <Controller */}
{/*           as={<TextField id="username" variant="outlined" />} */}
{/*           name="username" */}
{/*           type="email" */}
{/*           control={control} */}
{/*           helperText={errors?.username?.message} */}
{/*           error={(errors?.username?.message?.length ?? 0) > 0} */}
{/*           fullWidth */}
{/*         /> */}
{/*         <InputLabel>Password</InputLabel> */}
{/*         <Controller */}
{/*           as={ */}
{/*             <TextField */}
{/*               id="password" */}
{/*               variant="outlined" */}
{/*               fullWidth */}
{/*               InputProps={{ */}
{/*                 endAdornment: ( */}
{/*                   <InputAdornment position="end"> */}
{/*                     <IconButton */}
{/*                       aria-label="toggle password visibility" */}
{/*                       onClick={handleClickShowPassword} */}
{/*                     > */}
{/*                       {showPassword ? ( */}
{/*                         <svg */}
{/*                           data-v-1c1520bc="" */}
{/*                           width="23" */}
{/*                           height="24" */}
{/*                           viewBox="0 0 23 24" */}
{/*                           fill="none" */}
{/*                           xmlns="http://www.w3.org/2000/svg" */}
{/*                           className={styles.socialMedia} */}
{/*                         > */}
{/*                           <path */}
{/*                             data-v-1c1520bc="" */}
{/*                             d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z" */}
{/*                             fill="currentColor" */}
{/*                           /> */}
{/*                         </svg> */}
{/*                       ) : ( */}
{/*                         <svg */}
{/*                           data-v-1c1520bc="" */}
{/*                           width="23" */}
{/*                           height="24" */}
{/*                           viewBox="0 0 23 24" */}
{/*                           fill="none" */}
{/*                           xmlns="http://www.w3.org/2000/svg" */}
{/*                           className={styles.socialMedia} */}
{/*                         > */}
{/*                           <path */}
{/*                             data-v-1c1520bc="" */}
{/*                             d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" */}
{/*                             fill="currentColor" */}
{/*                           /> */}
{/*                         </svg> */}
{/*                       )} */}
{/*                     </IconButton> */}
{/*                   </InputAdornment> */}
{/*                 ), */}
{/*               }} */}
{/*             /> */}
{/*           } */}
{/*           name="password" */}
{/*           type={showPassword ? 'text' : 'password'} */}
{/*           control={control} */}
{/*           helperText={errors?.password?.message} */}
{/*           error={(errors?.password?.message?.length ?? 0) > 0} */}
{/*           fullWidth */}
{/*         /> */}
{/*         <div className={styles.forgotPassword}> */}
{/*           <AniLink */}
{/*             to="/reset/" */}
{/*             cover */}
{/*             direction="up" */}
{/*             bg="linear-gradient(180deg, #FFFFFF 0%, #F8DFEB 100%)" */}
{/*             duration={'0.8'} */}
{/*           > */}
{/*             {forgotPasswordLinkText} */}
{/*           </AniLink> */}
{/*         </div> */}
        <Button onClick={redirectToIdentityApp} variant="contained" color="primary">
          Log in
        </Button>
      </form>
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </>
  );
};

export default LoginForm;
