import React, { useState } from 'react';
import axios from 'axios';
import { useLocation } from '@reach/router';

import { getAxiosErrorMessage } from '../../../utils/util';
import { FormDataInterface } from './Login.interface';

const useLogin = () => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const location = useLocation();

  // Existing redirect that uses 'source' which is not implemented here yet
  const redirectToApp = (): void => {
    const params = new URLSearchParams(location.search);
    const source = params.get('source');
    if (source === null || source === undefined) {
      window.location.assign('/tradefloor/#!/');
    } else {
      window.location.assign(`/tradefloor/#!/${decodeURIComponent(source)}`);
    }
  };

  const onSubmit = async (data: FormDataInterface): Promise<void> => {
    axios
      .post('/tradefloor/public_api/login', {
        ...data,
        broker: 'OPEN_MARKETS',
      })
      .then((res) => {
        if (res.status === 200) {
          redirectToApp();
        } else {
          console.error(res);
          setErrorMessage(getAxiosErrorMessage(res));
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(getAxiosErrorMessage(error.response));
      });
  };

  return {
    onSubmit,
    errorMessage,
  };
};

export default useLogin;
