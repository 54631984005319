import React from 'react';

import { Container, Grid, Link } from '@tf/design-system-react';

import HelmetWrapper from '../../helmet-wrapper/HelmetWrapper';
import BackGroundCover from '../../background-cover/BackGroundCover';
import Template from '../../template/Template';
import LoginForm from './LoginForm';
import * as styles from './Login.module.scss';
import { seo } from '../../../common/seo';
import { copies } from '../../../common/copies';

const Login = () => {
  const mode = 'light';
  return (
    <Template fullScreen={true}>
      <HelmetWrapper
        metadata={{
          seoMetaTitle: seo.login.meta_title,
          seoMetaDescription: seo.login.meta_description,
          seoNoIndex: null,
          seoOgTitle: seo.login.og_title,
          seoOgDescription: seo.login.og_description,
          seoOgType: seo.login.og_type,
          seoOgImage: seo.login.og_image,
        }}
      />
      <div
        data-component-name="Login"
        data-component-library="ot-kontent-gatsby"
        className={styles.login}
      >
        <BackGroundCover
          className={styles.headerContainer}
          mode={mode}
          background={
            'transparent linear-gradient(180deg, #FFFFFF 0%, #F8DFEB 100%) 0% 0% no-repeat padding-box'
          }
          withCurve={true}
          curveColor={'#FFFFFF4D'}
          withDecorator={true}
          decoratorColor={'#FFFFFF4D'}
          addHeaderLogo
          animatedDecorator={true}
        >
          <Container>
            <Grid container justifyContent="center">
              <div className={styles.container}>
                <Grid container direction="column" spacing={2}>
                  <Grid item className={styles.header}>
                    {copies.welcome_back}
                  </Grid>
                  <Grid item className={styles.divider}>
                    <hr />
                  </Grid>
                  <Grid item className={styles.intro}>
                    <p>{copies.login_intro}</p>
                  </Grid>
                  <Grid item>
                    <LoginForm forgotPasswordLinkText={copies.forgot_password_cta} />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Container>
        </BackGroundCover>
      </div>
    </Template>
  );
};

export default Login;
